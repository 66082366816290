import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchProjects(context, payload) {
      return axios.get('project', payload)
    },
    async fetchReports(context, payload) {
      return axios.get('report', payload)
    },
    async fetchUsers(context, payload) {
      return axios.get('user', payload)
    },
    async fetchLink(context, payload) {
      return axios.get(`report/link/${payload.id}/${payload.channel}`)
    },
    async fetchReport(context, payload) {
      return axios.get(`report/${payload}`)
    },
    async addReport(context, payload) {
      const res = await axios.post('report', payload)

      const { data } = res

      if (data) {
        // eslint-disable-next-line no-underscore-dangle
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-center',
          props: {
            title: 'Оповещение',
            icon: 'InfoIcon',
            variant: 'success',
            text: 'Отчет добавлен',
          },
        })
      }

      return res
    },
    async updateReport(context, payload) {
      const res = await axios.put(`report/${payload.id}`, payload.data)

      const { data } = res

      if (data) {
        // eslint-disable-next-line no-underscore-dangle
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-center',
          props: {
            title: 'Оповещение',
            icon: 'InfoIcon',
            variant: 'success',
            text: 'Отчет обновлен',
          },
        })
      }

      return res
    },
    async updateReportForm(context, payload) {
      const res = await axios.put(`report/form/${payload.id}`, payload.data)

      const { data } = res

      if (data) {
        // eslint-disable-next-line no-underscore-dangle
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-center',
          props: {
            title: 'Оповещение',
            icon: 'InfoIcon',
            variant: 'success',
            text: 'Отчет обновлен',
          },
        })
      }

      return res
    },
    async deleteReport(context, payload) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        text: 'Удалить отчет?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios.delete(`report/${payload.id}`)
        },
      }).then(result => {
        if (result.isConfirmed) {
          const { data } = result.value
          if (data) {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$swal({
              icon: 'success',
              text: 'Отчет удален',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        }

        return result
      })
    },
  },
}
