import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchProjects(context, payload) {
      return axios.get('project', payload)
    },
    async fetchUsers(context, payload) {
      return axios.get('user', payload)
    },
    async fetchProject(context, payload) {
      return axios.get(`project/${payload}`)
    },
    async addProject(context, payload) {
      const res = await axios.post('project', payload)

      const { data } = res

      if (data) {
        // eslint-disable-next-line no-underscore-dangle
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-center',
          props: {
            title: 'Оповещение',
            icon: 'InfoIcon',
            variant: 'success',
            text: 'Проект добавлен',
          },
        })
      }

      return res
    },
    async updateProject(context, payload) {
      const res = await axios.put(`project/${payload.id}`, payload.data)

      const { data } = res

      if (data) {
        // eslint-disable-next-line no-underscore-dangle
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-center',
          props: {
            title: 'Оповещение',
            icon: 'InfoIcon',
            variant: 'success',
            text: 'Проект обновлен',
          },
        })
      }

      return res
    },
    async updateProjectForm(context, payload) {
      const res = await axios.put(`project/form/${payload.id}`, payload.data)

      const { data } = res

      if (data) {
        // eslint-disable-next-line no-underscore-dangle
        this._vm.$toast({
          component: ToastificationContent,
          position: 'top-center',
          props: {
            title: 'Оповещение',
            icon: 'InfoIcon',
            variant: 'success',
            text: 'Проект обновлен',
          },
        })
      }

      return res
    },
    async deleteProject(context, payload) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        text: 'Удалить отчет?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios.delete(`project/${payload.id}`)
        },
      }).then(result => {
        if (result.isConfirmed) {
          const { data } = result.value
          if (data) {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$swal({
              icon: 'success',
              text: 'Проект удален',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        }

        return result
      })
    },
  },
}
