<template>
  <b-card>

    <b-row>

      <!-- Project Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- Project Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ projectData.name }}
              </h4>
              <span class="card-text">{{ projectData.channels.join(', ') }}</span>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="TypeIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">KPI Тип</span>
            </th>
            <td class="pb-50">
              {{ projectData.kpi_type }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PieChartIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">KPI Значение</span>
            </th>
            <td class="pb-50">
              {{ projectData.kpi_sum }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BRow, BCol,
  },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
  },
  setup() {},
}
</script>

<style>

</style>
