<template>
  <b-sidebar
    id="add-new-report-sidebar"
    :visible="isAddNewReportSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-report-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{reportData.id ? 'Обновить отчет' : 'Добавить отчет'}}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2 relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-overlay
            :show="$store.state.app.loadingCreate"
            no-wrap
            :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
          />

          <validation-provider
            #default="validationContext"
            name="Дата"
            rules="required"
          >
            <b-form-group
              label="Дата"
              label-for="createdAt"
            >

              <b-form-datepicker
                id="createdAt"
                v-model="reportData.createdAt"
                :state="getValidationState(validationContext)"
                trim
                @input="val => {
                  $emit('update:value', val)
                }"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="ru-RU"
                class="text-left"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Потрачено"
          >
            <b-form-group
              label="Потрачено"
              label-for="spent"
            >
              <b-form-input
                id="spent"
                v-model="reportData.spent"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                step="0.01"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Охват/Показы"
          >
            <b-form-group
              label="Охват/Показы"
              label-for="coverage"
            >
              <b-form-input
                id="coverage"
                v-model="reportData.coverage"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                step="0.01"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Клики"
          >
            <b-form-group
              label="Клики"
              label-for="clicks"
            >
              <b-form-input
                id="clicks"
                v-model="reportData.clicks"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                step="0.01"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Лиды"
          >
            <b-form-group
              label="Лиды"
              label-for="leads"
            >
              <b-form-input
                id="leads"
                v-model="reportData.leads"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                step="0.01"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Валидных Лидов"
          >
            <b-form-group
              label="Валидных Лидов"
              label-for="validLeads"
            >
              <b-form-input
                id="validLeads"
                v-model="reportData.validLeads"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                step="0.01"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- <validation-provider
            #default="validationContext"
            name="Подписчиков"
            rules="required"
          >
            <b-form-group
              label="Подписчиков"
              label-for="subscribers"
            >
              <b-form-input
                id="subscribers"
                v-model="reportData.subscribers"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Комментариев"
            rules="required"
          >
            <b-form-group
              label="Комментариев"
              label-for="comments"
            >
              <b-form-input
                id="comments"
                v-model="reportData.comments"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{reportData.id ? 'Обновить' : 'Добавить'}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Отмена
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput,
  BFormInvalidFeedback, BButton,
  BOverlay, BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BOverlay,
    BFormDatepicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewReportSidebarActive',
    event: 'update:is-add-new-report-sidebar-active',
  },
  props: {
    isAddNewReportSidebarActive: {
      type: Boolean,
      required: true,
    },
    reportData: {
      type: Object,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    const blankReportData = {
      createdAt: new Date(),
      spent: 0,
      coverage: 0,
      clicks: 0,
      leads: 0,
      validLeads: 0,
      subscribers: 0,
      comments: 0,
    }

    const resetreportData = () => {
      /* eslint-disable */
      props.reportData.id = null,
      props.reportData.createdAt = blankReportData.createdAt
      props.reportData.spent = blankReportData.spent
      props.reportData.coverage = blankReportData.coverage
      props.reportData.clicks = blankReportData.clicks
      props.reportData.leads = blankReportData.leads
      props.reportData.validLeads = blankReportData.validLeads
      props.reportData.subscribers = blankReportData.subscribers
      props.reportData.comments = blankReportData.comments
      /* eslint-enable */
    }

    const onSubmit = () => {
      if (props.reportData.id) {
        store.dispatch('app-reports/updateReport', {
          id: props.reportData.id,
          data: {
            createdAt: props.reportData.createdAt,
            user_id: props.projectData.user_id,
            project_id: props.projectData.id,
            spent: props.reportData.spent,
            coverage: props.reportData.coverage,
            clicks: props.reportData.clicks,
            leads: props.reportData.leads,
            validLeads: props.reportData.validLeads,
            subscribers: props.reportData.subscribers,
            comments: props.reportData.comments,
          },
        })
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-report-sidebar-active', false)
          })
      } else {
        store.dispatch('app-reports/addReport', {
          ...props.reportData,
          user_id: props.projectData.user_id,
          project_id: props.projectData.id,
        })
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-report-sidebar-active', false)
          })
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetreportData)

    return {
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
