import { ref, watch, computed } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function useProjectViewReportsList(props, ctx) {
  const refListTable = ref(null)
  const isAddNewReportSidebarActive = ref(false)
  const loading = ref(false)
  const loadingLink = ref(false)
  const projectFilter = ref(undefined)

  // Table Handlers
  /* eslint-disable */
  const tableColumns = [
    { key: 'createdAt', sortable: true, label: 'Дата', thClass: 'text-left' },
    { key: 'channel', sortable: true, label: 'Канал', thClass: 'text-left' },
    { key: 'spent', sortable: true, label: 'Потрачено', thClass: 'text-left', tdClass: 'text-left' },
    { key: 'coverage', sortable: true, label: 'Охват/Показы', thClass: 'text-left', tdClass: 'text-left' },
    { key: 'clicks', sortable: true, label: 'Клики', thClass: 'text-left', tdClass: 'text-left' },
    { key: 'leads', sortable: true, label: 'Лиды', thClass: 'text-left', tdClass: 'text-left' },
    { key: 'validLeads', sortable: true, label: 'Валидных Лидов', thClass: 'text-left', tdClass: 'text-left' },
    { key: 'cpc', sortable: true, label: 'СРС', thClass: 'text-left', tdClass: 'text-left' },
    { key: 'ctr', sortable: true, label: 'CTR', thClass: 'text-left', tdClass: 'text-left' },
    { key: 'cv', sortable: true, label: 'CR клик > лид', thClass: 'text-left', tdClass: 'text-left' },
    { key: 'cpl', sortable: true, label: 'CPL', thClass: 'text-left', tdClass: 'text-left' },
    { key: 'cv2', sortable: true, label: 'CR лид > вал. лид', thClass: 'text-left', tdClass: 'text-left' },
    { key: 'cpvl', sortable: true, label: 'CPVL', thClass: 'text-left', tdClass: 'text-left' },
    { key: 'actions', label: '', sortable: false },
  ]


  const perPage = ref(25)
  const total = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref('10')

  const blankReportData = {
    createdAt: new Date(),
    spent: '',
    coverage: '',
    clicks: '',
    leads: '',
    validLeads: '',
    subscribers: 0,
    comments: 0,
  }

  const report = ref(JSON.parse(JSON.stringify(blankReportData)))

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: total.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchReports = (ctx, callback) => {
    loading.value = true
    store
      .dispatch('app-reports/fetchReports', {
        params: {
          filter: JSON.stringify({
            project_id: projectFilter.value,
          }),
          perPage: perPage.value,
          page: currentPage.value,
        },
      })
      .then(response => {
        const { data, count } = response.data

        callback(data)
        total.value = count
        loading.value = false
      })
      .catch(() => {
        loading.value = false
      })
  }

  const deleteReport = report => {
    store.dispatch('app-reports/deleteReport', report).then(() => {
      refetchData()
    })
  }

  const doCopy = txt => {
    ctx.root.$copyText(txt).then(() => {
      ctx.root.$toast({
        component: ToastificationContent,
        props: {
          title: 'Ссылка скопирована',
          icon: 'BellIcon',
        },
      })
    }, () => {
      ctx.root.$toast({
        component: ToastificationContent,
        props: {
          title: 'Не удалось скопировать',
          icon: 'BellIcon',
        },
      })
    })
  }

  const fetchLink = () => {
    loadingLink.value = true
    store.dispatch('app-reports/fetchLink', props.projectData.id).then(res => {
      doCopy(`https://lk.trafficstats.ru/report-form/${res.data}`)
      loadingLink.value = false
      refetchData()
    })
  }

  const editReport = rep => {
    isAddNewReportSidebarActive.value = true
    report.value = { ...rep }
  }

  return {
    isAddNewReportSidebarActive,
    loading,
    loadingLink,
    report,
    fetchReports,
    editReport,
    deleteReport,
    fetchLink,
    doCopy,
    tableColumns,
    perPage,
    currentPage,
    total,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    refetchData,

    projectFilter,
  }
}
