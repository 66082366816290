<template>
  <div>

    <b-overlay
      :show="$store.state.app.loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="projectData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('message.Error fetching project data') }}
      </h4>
      <div class="alert-body">
        {{ $t('message.No project found with this project id') }}
        <b-link
          class="alert-link"
          :to="{ name: 'projects'}"
        >
          {{ $t('message.Check Project List for other projects') }}
        </b-link>
      </div>
    </b-alert>

    <div v-if="projectData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <project-view-project-info-card :project-data="projectData" />
          <project-view-reports-list :project-data="projectData" />
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BOverlay,
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import projectsStoreModule from '../projectsStoreModule'
import ProjectViewProjectInfoCard from './ProjectViewProjectInfoCard.vue'
import ProjectViewReportsList from './ProjectViewReportsList.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BOverlay,

    // Local Components
    ProjectViewProjectInfoCard,
    ProjectViewReportsList,
  },
  setup() {
    const projectData = ref(null)

    const APP_STORE_MODULE_NAME = 'app-projects'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, projectsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-projects/fetchProject', router.currentRoute.params.id)
      .then(response => {
        const { data } = response
        projectData.value = data
      })
      .catch(error => {
        if (error.response.status === 404) {
          projectData.value = undefined
        }
      })

    return {
      projectData,
    }
  },
}
</script>

<style>

</style>
